<nav class="bg-white border-b border-app-bg sticky top-0 z-30 w-full">
  <div class="">
    <div class="flex items-center w-full">
      <div class="flex items-center justify-center w-72">
        <button
          (click)="toggleMobileSideBar()"
          id="toggleSidebarMobile"
          aria-expanded="true"
          aria-controls="sidebar"
          class="ml-4 text-gray-600 hover:text-gray-900 cursor-pointer p-2 hover:bg-gray-100 focus:bg-gray-100 focus:ring-2 focus:ring-gray-100 rounded">
          <i *ngIf="isSideBarOpen" class="fas fa-bars w-4"></i>
          <i *ngIf="!isSideBarOpen" class="fas fa-bars w-4"></i>
        </button>
        <a
          routerLink="/"
          (click)="onClickLogo()"
          class="cursor-pointer text-xl font-bold w-64 h-16 object-center object-cover flex items-center lg:justify-center lg:border-r lg:border-app-bg py-2">
          <img
            src="assets/logo/shamolima.png"
            class="notPreview"
            alt="Shamolima Logo" />
        </a>
        <!--        <form class="hidden lg:block ml-4 w-[36rem]">-->
        <!--          <label for="topbar-search" class="sr-only">Search</label>-->
        <!--          <div class="relative">-->
        <!--            <div class="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">-->
        <!--              <i class="far fa-search text-gray-500"></i>-->
        <!--            </div>-->
        <!--            <input type="text" name="email" id="topbar-search"-->
        <!--                   class="border-none bg-input  text-web-text-secondary sm:text-sm rounded-lg focus:ring-cyan-600 focus:border-cyan-600 block w-full pl-10 p-2.5"-->
        <!--                   placeholder="Search" (keyup)="onSearch($event)">-->
        <!--          </div>-->
        <!--        </form>-->
        <ul>
          <li *ngFor="let result of searchSuggestions">
            <a>{{ result.name }}</a>
          </li>
        </ul>
      </div>
      <div class="flex items-center space-x-3 justify-end w-full mr-2">
        <!-- Search mobile -->
        <button
          (click)="onClickSearchOnMobile()"
          id="toggleSidebarMobileSearch"
          type="button"
          class="lg:hidden text-gray-500 hover:text-gray-900 hover:bg-gray-100 p-2 rounded-lg">
          <span class="sr-only">Search</span>
          <!-- Search icon -->
          <i class="fas fa-search"></i>
        </button>
        <button matTooltip="Logout" (click)="onClickLogOut()"
                class="lg:hidden text-gray-500 hover:text-gray-900 hover:bg-gray-100 p-2 rounded-lg">
          <img loading="lazy" class="notPreview" src="assets/svg/logout.svg" alt="logout">
        </button>

        <button class="flex items-center" (click)="isProfileOpened = !isProfileOpened">
          <div
            id="dropdownDefaultButton"
            class="hidden lg:flex space-x-2 items-center font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-blue-600"
            type="button">
            <div
              class="w-8 h-8 overflow-hidden rounded-full border border-gray-400 object-center object-cover">
              <img
                class="w-full h-full notPreview"
                [src]="image | image"
                alt="profile-img" />
            </div>
            <span class="text-gray-500">{{ name }}</span>
            <i
              *ngIf="!isProfileOpened"
              class="fas fa-caret-down ml-2 text-not-black"></i>
            <i
              *ngIf="isProfileOpened"
              class="fas fa-caret-up ml-2 text-not-black"></i>
          </div>
        </button>
      </div>
    </div>
  </div>
  <div
    appGetOutsideModalClick
    (clickOutside)="isProfileOpened = !$event"
    *ngIf="isProfileOpened"
    id="dropdown"
    class="z-10 absolute top-full right-0 bg-white divide-y divide-gray-100 rounded-lg shadow w-44 dark:bg-gray-700">
    <ul
      class="py text-sm text-gray-700 dark:text-gray-200"
      aria-labelledby="dropdownDefaultButton">
      <li
        class="block cursor-pointer px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white">
        <a (click)="onClickLogOut()">Sign Out</a>
      </li>
    </ul>
  </div>
</nav>
