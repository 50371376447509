<div
  *ngIf="isOpen"
  class="fixed rounded-lg z-40 inset-0 flex items-center justify-center bg-black bg-opacity-50">
  <div class="bg-white relative rounded-lg max-w-[75vw] min-w-[30vw]">
    <button
      (click)="closeModal()"
      class="absolute top-4 right-4 p-1 h-6 z-50 w-6 shadow-lg bg-white rounded-full flex items-center justify-center">
      <i class="fal fa-times text-lg"></i>
    </button>
    <div appGetOutsideModalClick (clickOutside)="onClick($event)">
      <ng-content></ng-content>
    </div>
  </div>
</div>
